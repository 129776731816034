import { useState } from "react";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

export function MySelect({
  options,
  value,
  isMultiple,
  isCreatable,
  placeholder,
  onChange,
}: {
  options: any[];
  value: any;
  isMultiple: boolean;
  isCreatable?: boolean;
  placeholder?: string | null | undefined;
  onChange: (newValue: any) => void;
}) {
  const [localValue, setLocalValue] = useState(value);
  const [localOptions, setLocalOptions] = useState(options || []);
  function onValueChange(newValue: any) {
    setLocalValue(newValue);
    onChange(newValue);
  }

  function onOptionsChange(newElement: any) {
    setLocalOptions([
      { value: newElement, label: newElement },
      ...localOptions,
    ]);
    if (isMultiple) {
      console.log("the inside newValue", [...(localValue || []), newElement]);
      onValueChange([...(localValue || []), newElement]);
    } else {
      onValueChange(newElement);
    }
  }

  const styles = {
    control: (base: any) => ({
      ...base,
      border: 0,
      boxShadow: "none",
      background: "RGBA(0,0,0,0.08)",
      padding: "10px 5px",
      borderColor: "transparent",
    }),
    menuList: (base: any) => ({
      ...base,
      maxHeight: "200px",
      background: "white",
      zIndex: 999999999,
      borderRadius: "5px",
    }),
    menu: (base: any) => ({
      ...base,
      background: "white",
      zIndex: 999999999,
      borderRadius: "5px",
    }),
    menuPortal: (base: any) => ({
      ...base,
      zIndex: 999999999,
      borderRadius: "5px",
    }),
    dropdownIndicator: (base: any, state: any) => ({
      ...base,
      borderLeft: "none",
      padding: "0px",
      paddingTop: "2px",
      display: state.hasValue ? "none" : null,
      borderColor: "transparent",
    }),
    clearIndicator: (base: any, state: any) => ({
      ...base,
      display: "none",
    }),
    indicatorSeparator: (base: any) => ({
      color: "transparent",
      display: "none",
    }),
  };

  let portalTarget = document.body;

  if (isMultiple) {
    if (isCreatable) {
      return (
        <CreatableSelect
          isSearchable={true}
          isMulti={true}
          placeholder={"Contient les element suivants..."}
          options={localOptions}
          menuPortalTarget={portalTarget}
          styles={{
            ...styles,
          }}
          onCreateOption={(inputValue) => {
            onOptionsChange(inputValue);
          }}
          value={
            localOptions?.filter((option) =>
              localValue?.includes(option.value)
            ) || []
          }
          onChange={(newValue) => {
            onValueChange(newValue?.map((value) => value.value) || []);
            console.log("the newValueeeeeeeee", {
              th: newValue?.map((value) => value.value) || [],
              localValue,
            });
          }}
        ></CreatableSelect>
      );
    }

    return (
      <Select
        isSearchable={true}
        isMulti={true}
        placeholder={placeholder}
        options={localOptions}
        styles={styles}
        menuPortalTarget={portalTarget}
        value={
          localOptions?.filter((option) =>
            localValue?.includes(option.value)
          ) || []
        }
        onChange={(newValue) => {
          onValueChange(newValue?.map((option) => option.value) || []);
        }}
      ></Select>
    );
  }

  if (isCreatable) {
    return (
      <CreatableSelect
        isSearchable={true}
        isMulti={false}
        placeholder={"Contient les element suivants..."}
        options={localOptions}
        styles={styles}
        value={localOptions?.find((option) => option.value == localValue)}
        menuPortalTarget={portalTarget}
        onCreateOption={(inputValue) => {
          onOptionsChange(inputValue);
        }}
        onChange={(newValue) => {
          console.log("the not so new value", newValue);
          onValueChange(newValue.value);
        }}
      ></CreatableSelect>
    );
  }

  return (
    <Select
      isSearchable={true}
      isMulti={false}
      placeholder={placeholder}
      options={localOptions}
      styles={styles}
      value={localOptions?.find((option) => option.value == localValue)}
      menuPortalTarget={portalTarget}
      onChange={(newValue) => {
        onValueChange(newValue.value);
      }}
    ></Select>
  );
}
