import { Button, CornerIconButton } from "@/src/components/buttons";
import { openDialog } from "@/src/components/dialogs";
import { InputChecker } from "@/src/components/formValidation";
import { Upload } from "@/src/data/allTypes";
import { getImgUrl, getUserId, sleep, uid } from "@/src/utils/utils";
import axios from "axios";
import imageCompression from "browser-image-compression";
import { ChangeEvent, useRef, useState } from "react";
import { FaRegFileAlt, FaRegFileVideo } from "react-icons/fa";
import { RiBodyScanLine } from "react-icons/ri";
import { TbCamera } from "react-icons/tb";
import { ImageViewer } from "./imageViewer";
import apiFetch from "../utils/apiFetch";

export function UploadSpot({
  value,
  additionalUploadData,
  onChange,
}: {
  value?: Upload | null | undefined;
  additionalUploadData?: Record<string, any>;
  onChange: (newValue: Upload) => void;
}) {
  return (
    <UploadSpots
      gridCount={1}
      spotsCount={1}
      maxSpotsCount={1}
      isUnique={true}
      additionalUploadData={additionalUploadData}
      value={value ? [value] : []}
      onChange={(newValues) => {
        onChange(newValues[0]);
      }}
    />
  );
}

export function UploadSpots({
  id,
  isRequired,
  name,
  value,
  gridCount,
  maxSpotsCount,
  isUnique,
  spotsCount,
  additionalUploadData,
  onChange,
}: {
  id?: string | null;
  isRequired?: boolean;
  name?: string;
  value?: (Upload | null | undefined)[] | null | undefined;
  gridCount?: number;
  spotsCount?: number;
  isUnique?: boolean;
  maxSpotsCount?: number;
  additionalUploadData?: Record<string, any>;
  onChange: (newValue: Upload[]) => void;
}) {
  const inputRef = useRef(null);

  let initialValue: Record<string, Upload> = {};

  if (Array.isArray(value))
    for (let item of value || []) {
      if (!item) continue;
      if (typeof item != "object") continue;
      item.name ||= uid();
      item.progress = 0;
      item.error = null;
      initialValue[item.name] = item;
    }

  const [overridenFile, setOverridenFile] = useState<string | null>("");
  const [files, setFiles] = useState<Record<string, File>>({});
  const [uploads, setUploads] = useState<Record<string, Upload>>(initialValue);

  let spots = [];
  for (let i = 0; i < (spotsCount || 3); i++) {
    spots.push(
      <div
        key={uid()}
        className="uploadSpot"
        onClick={(e) => {
          e.stopPropagation();
          setOverridenFile(null);
          let current: any = inputRef.current;
          current?.click?.();
        }}
      >
        <TbCamera size={60} color="RGBA(0,0,0,0.2)" />
      </div>
    );
  }

  async function handleChange(e: ChangeEvent<HTMLInputElement>) {
    if (!e.target.files) return;
    let newFiles = Array.from(e.target.files);
    if (overridenFile) {
      delete uploads[overridenFile];
      setOverridenFile(null);
    }

    for (let file of newFiles) {
      uploads[file.name] = {
        ...((additionalUploadData || {}) as any),
        fileType: "image",
        name: file.name,
        original_name: file.name,
        error: null,
        url: await getBase64(file),
        progress: 0,
        compressionProgress: 0,
        creator: getUserId(),
      };

      // await openDialog({
      //   id: "uploadingDialog",
      //   title: "Compression",
      //   content: (
      //     <CompressionDialog
      //       file={file}
      //       onCancel={() => {
      //         closeDialog("uploadingDialog");
      //       }}
      //       onChange={(compressedFile) => {
      //         file = compressedFile;
      //         closeDialog("uploadingDialog");
      //       }}
      //     />
      //   ),
      //   footer: <div></div>,
      // });

      handleSubmit(file);
    }

    setUploads(uploads);
    await sleep(100);
  }

  function addToUploadProp(fileName: string, newProp: Partial<Upload>) {
    uploads[fileName] = {
      ...uploads[fileName],
      ...newProp,
    };
    setUploads({ ...uploads });
  }

  async function handleSubmit(file: File | null | undefined) {
    const url = `${process.env.BACKEND_BASE_URL}/uploads/`;
    const formData = new FormData();
    if (!file) return;
    files[file.name] = file;
    setFiles({ ...files });
    formData.append("file", file);
    formData.append("fileName", file.name);
    axios
      .post(url, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
        onUploadProgress: function (progressEvent: any) {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          if (percentCompleted == 100) percentCompleted = 99;

          addToUploadProp(file.name, { progress: percentCompleted });
        },
      })
      .then(async (res) => {
        const { data } = res;

        let newUploadObject: Upload = {
          ...uploads[file.name],
          url: data.url,
          name: data.fileName,
        };

        newUploadObject = await apiFetch("uploads", "addOne", {
          upload: newUploadObject,
        });

        addToUploadProp(file.name, newUploadObject);
        await sleep(100);
        onChange?.(
          Object.values(uploads).filter((upload) => upload.name != null)
        );
      })
      .catch((err) => {
        addToUploadProp(file.name, {
          error: JSON.stringify(err),
        });
      });
  }

  function getBase64(file: File) {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result as string;
        resolve(baseURL);
      };
    });
  }

  return (
    <InputChecker
      id={id}
      name={name}
      value={uploads}
      isRequired={isRequired}
      isUploads={true}
      hasChanged={false}
    >
      <div className="field">
        <input
          ref={inputRef}
          type="file"
          onChange={handleChange}
          multiple
          style={{ display: "none" }}
        />
        {name != null && <div className="label">{name}</div>}

        <div className={`grid c${gridCount || 3} gap15`}>
          {Object.values(uploads)?.map((uploadInfo, index) => {
            const file = files[uploadInfo.name || ""];
            let uploadedUrl = getImgUrl(uploadInfo);
            if (!uploadedUrl && file) uploadedUrl = URL.createObjectURL(file);
            const { progress, error } = uploadInfo;
            const fileType = getFileType(uploadInfo.name || "");
            const isStillUploading = (progress || 0) < 100 && progress !== 0;
            console.log({
              progress,
              error,
              fileType,
              isStillUploading,
              uploadInfo,
            });

            return (
              <div
                className="uploadPreview center"
                key={uid()}
                onClick={(e) => {
                  e.stopPropagation();
                  setOverridenFile(uploadInfo.name || "");
                  let current: any = inputRef.current;
                  current?.click?.();
                }}
              >
                <CornerIconButton
                  type="remove"
                  onClick={() => {
                    let key = Object.keys(uploads)[index];
                    delete uploads[key];
                    setUploads(uploads);

                    onChange?.(
                      Object.values(uploads).filter(
                        (upload) => upload.url != null
                      )
                    );
                  }}
                ></CornerIconButton>
                {fileType == "image" && !isStillUploading && (
                  <img src={getImgUrl(uploadInfo)} alt="Uploaded content" />
                )}

                {fileType == "image" && isStillUploading && (
                  <img src={uploadInfo.url || ""} alt="Uploaded content" />
                )}
                {fileType == "file" && <FaRegFileAlt size={25} />}
                {fileType == "dicom" && <RiBodyScanLine size={25} />}
                {fileType == "dicom" && <div className="bold">Dicom</div>}
                {fileType == "video" && <FaRegFileVideo size={25} />}

                {progress != null && progress > 0 && (
                  <div className="uploadProgress">
                    <div>{progress}%</div>
                  </div>
                )}
                {error != null && (
                  <div className="uploadError">
                    Error uploading file: {error }
                  </div>
                )}
              </div>
            );
          })}
          {isUnique == true && Object.values(uploads).length ? null : spots}
        </div>
      </div>
    </InputChecker>
  );
}

function CompressionDialog({
  file,
  onChange,
  onCancel,
}: {
  file: File;
  onChange: (newFile: File) => void;
  onCancel: () => void;
}) {
  const { progress, error, compress } = useCompress();
  return (
    <div>
      <div className="gray p20 center mbottom10">
        Voulez Compresser le fichier ?
      </div>
      <div className="c2 gap10">
        <Button
          type="secondary"
          onClick={() => {
            onCancel();
          }}
        >
          Ne Pas compresser
        </Button>
        {progress > 0 && <div>Compression en cours... {progress}%</div>}
        {error != null && <div>Erreur: {error}</div>}
        <Button
          color="blue"
          isDisabled={progress > 0}
          onClick={async () => {
            let compressedFile = await compress(file);
            if (compressedFile) onChange(compressedFile);
          }}
        >
          Compresser
        </Button>
      </div>
    </div>
  );
}

function useCompress() {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [compressedFile, setCompressedFile] = useState<
    File | null | undefined
  >();
  async function compress(file: File | null | undefined): Promise<File | null> {
    if (!file) return null;
    setProgress(0);
    setError("");
    const imageFile = file;
    console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: 0.9,
      maxWidthOrHeight: 1000,
      useWebWorker: true,
      onProgress: (percent: number) => {
        setProgress(percent);
      },
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob
      ); // true
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB
      setProgress(0);
      setCompressedFile(compressedFile);
      setError(null);
      return compressedFile;
    } catch (error) {
      setProgress(0);
      setError(JSON.stringify(error));
      console.log(error);
      return null;
    }
  }
  return { compressedFile, progress, error, compress };
}

export function UploadCard({ upload }: { upload: Upload | null | undefined }) {
  if (!upload) return null;
  const fileType = getFileType(upload.name || "");

  return (
    <div
      className="uploadCard center pointer"
      onClick={() => {
        if (fileType == "dicom") {
          openDialog({
            id: "dicomViewerDialog",
            title: "Visualiser le fichier",
            additionalClasses: ["noPadding"],
            content: (
              <iframe
                id="dicomIframe"
                src={`${process.env.BACKEND_BASE_URL!}/dicom?filename=${
                  upload.name
                }`}
              ></iframe>
            ),
          });
        }

        if (fileType != "image") {
          window.open(
            `${process.env.BACKEND_BASE_URL!}/my_uploads/${upload.name}`,
            "_blank"
          );
        }
      }}
    >
      {fileType == "image" && <img src={getImgUrl(upload)} alt="upload" />}
      {fileType == "file" && <FaRegFileAlt size={25} />}
      {fileType == "dicom" && <RiBodyScanLine size={25} />}
      {fileType == "dicom" && <div className="bold">Dicom</div>}
      {fileType == "video" && <FaRegFileVideo size={25} />}
      <div
        className="ellipsis mtop5"
        style={{
          width: "100%",
          overflow: "hidden",
        }}
      >
        {upload.original_name || upload.name}
      </div>
    </div>
  );
}

export function UploadedImage({
  upload,
  style,
  className,
}: {
  upload: Upload | null | undefined;
  style?: React.CSSProperties;
  className?: string;
}) {
  if (!upload) return null;
  const fileType = getFileType(upload.name || "");

  if (fileType == "video")
    return (
      <video
        width="400"
        controls
        className={className}
        style={style}
        loop
        autoPlay
      >
        <source src={getImgUrl(upload)} type="video/mp4" />
        Your browser does not support HTML video.
      </video>
    );

  return (
    <img
      src={getImgUrl(upload)}
      alt="upload"
      className={`br bs center pointer ${className}`}
      style={style}
    />
  );
}

function getFileType(
  name: string | null | undefined
): "image" | "file" | "dicom" | "video" | null {
  if (!name) return null;
  let parts = name.split(".");
  let extension = parts[parts.length - 1]?.toLowerCase();
  if (extension == "db") return "file";
  if (extension == "dcm" || extension == "dicom") return "dicom";
  if (["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp"].includes(extension))
    return "image";
  if (["mp4", "avi", "mov", "mkv", "webm"].includes(extension)) return "video";
  return "file";
}

export function UploadCards({
  uploads,
  count,
}: {
  uploads: (Upload | null | undefined)[] | null | undefined;
  count?: number;
}) {
  if (!uploads) return <></>;
  return (
    <ImageViewer
      images={uploads
        .filter((upload) => getFileType(upload?.name) == "image")
        .map((upload) => {
          return { src: getImgUrl(upload) };
        })}
    >
      <div className={`grid gap10 c${count || "3"}`}>
        {uploads.map((upload) => {
          if (!upload) return;
          return <UploadCard key={uid()} upload={upload}></UploadCard>;
        })}
      </div>
    </ImageViewer>
  );
}
