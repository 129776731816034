import { Button, CornerIconButton } from "@/src/components/buttons";
import SearchBar from "@/src/components/searchBar";
import StaggeredAnimation from "@/src/components/staggeredAnimationComponent";
import { openToast } from "@/src/utils/toasts";
import { MouseEventHandler, useState } from "react";
import { FaRegCircle } from "react-icons/fa";
import { FaCircleCheck } from "react-icons/fa6";
import { IoMdAdd, IoMdCloseCircleOutline } from "react-icons/io";
import { Reference } from "../data/allTypes";
import apiFetch from "../utils/apiFetch";
import { getUserId } from "../utils/utils";
import SearchKeyHighliter from "./seachkeyHighlighter";

export type CardColor =
  | "simple"
  | "gray"
  | "red"
  | "green"
  | "blue"
  | "orange"
  | "purple"
  | "pink"
  | "white";

export const cardColors = {
  simple: {
    id: "simple",
    color: "rgba(0, 85, 255,1)",
    secondaryColor: "rgba(255, 255, 255, 0.242)",
  },
  gray: {
    id: "gray",
    color: "rgba(0, 0, 0,0.8)",
    secondaryColor: "rgba(0, 0, 0,0.1)",
  },
  red: {
    id: "red",
    color: "rgba(255, 0, 0,1)",
    secondaryColor: "rgba(255, 0, 0,0.1)",
  },
  green: {
    id: "green",
    color: "rgba(2, 194, 75, 1)",
    secondaryColor: "rgba(2, 194, 75,0.12)",
  },
  blue: {
    id: "blue",
    color: "#102754",
    secondaryColor: "rgba(255, 255, 255, 0.242)",
  },
  orange: {
    id: "orange",
    color: "rgba(255, 124, 2,1)",
    secondaryColor: "rgba(255, 124, 2,0.1)",
  },
  purple: {
    id: "purple",
    color: "rgba(144, 0, 255,1)",
    secondaryColor: "rgba(144, 0, 255,0.1)",
  },
  pink: {
    id: "pink",
    color: "rgba(255, 77, 115,1)",
    secondaryColor: "rgba(255, 77, 115,0.1)",
  },
  white: {
    id: "white",
    color: "rgba(48, 127, 159, 0.09)",
    secondaryColor: "rgba(255, 255, 255,0.1)",
  },
};

export function Card({
  color,
  className,
  animationIndex,
  title,
  subTitle,
  leading,
  trailing,
  onClick,
  onEdit,
  onMore,
  onDelete,
  children,
}: {
  color: CardColor;
  className?: string;
  animationIndex?: number;
  title?: any;
  subTitle?: any;
  leading?: any;
  trailing?: any;
  onClick?: (e: any) => void;
  onEdit?: (e: any) => void;
  onMore?: (e: any) => void;
  onDelete?: (e: any) => void;
  children?: any;
}) {
  let typeInfo = cardColors[color];
  const [isLoading, setIsLoading] = useState(false);

  return (
    <StaggeredAnimation index={animationIndex}>
      <div
        className={`itemCard ${isLoading ? "loading" : ""} ${
          className || ""
        } ${color}-card`}
        style={{ background: typeInfo?.secondaryColor, boxShadow: "none" }}
        onClick={async (e) => {
          try {
            setIsLoading(true);
            await onClick?.(e);
            setIsLoading(false);
          } catch (e: any) {
            openToast("error", e?.message || e?.toString());
            setIsLoading(false);
            throw e;
          }
        }}
      >
        {onMore && (
          <CornerIconButton onClick={onMore} type="more"></CornerIconButton>
        )}
        {onEdit && (
          <CornerIconButton
            type="edit"
            position={onMore != null ? "secondary" : "primary"}
            onClick={onEdit}
          ></CornerIconButton>
        )}
        {onDelete && (
          <CornerIconButton
            type="delete"
            position={
              onEdit != null || onMore != null ? "secondary" : "primary"
            }
            onClick={async (e) => {
              setIsLoading(true);
              await onDelete?.(e);
              setIsLoading(false);
            }}
          ></CornerIconButton>
        )}
        <div className="itemCardBackground"></div>
        <div className="itemCardContent">
          {leading || <div></div>}
          <div className="" style={{ height: "100%" }}>
            <div className=""  style={{ height: "100%" }}>
              <div className="bold title" style={{ color: typeInfo?.color }}>
                {title || ""}
              </div>
              <div className="gray subTitle">{subTitle || ""}</div>
              {children}
            </div>
          </div>
          {trailing}
        </div>
      </div>
    </StaggeredAnimation>
  );
}

export function CheckboxCard({
  color,
  className,
  status,
  title,
  subTitle,
  trailing,
  onClick,
  onMore,
  onEdit,
  onDelete,
  children,
}: {
  color: CardColor;
  className?: string;
  status: "active" | "pending" | "inactive" | "problem";
  title: any;
  subTitle?: any;
  trailing?: any;
  onClick?: (e: any) => void;
  onMore?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  children?: any;
}) {
  let typeInfo = cardColors[color];
  let colorValue = typeInfo?.color;
  return (
    <Card
      color={color}
      className={className}
      title={title}
      subTitle={subTitle}
      leading={
        <>
          {status == "active" ? (
            <FaCircleCheck size={18} style={{ color: colorValue }} />
          ) : status == "problem" ? (
            <IoMdCloseCircleOutline size={20} style={{ color: colorValue }} />
          ) : (
            <FaRegCircle size={18} style={{ color: colorValue }} />
          )}
        </>
      }
      trailing={trailing}
      onClick={onClick}
      onEdit={onEdit}
      onMore={onMore}
      onDelete={onDelete}
    >
      {children}
    </Card>
  );
}

export function CompartmentCard({
  id,
  color,
  className,
  title,
  buttonTitle,
  onClick,
  secondaryButtonTitle,
  onSecondaryClick,
  searchKey,
  onSearch,
  children,
}: {
  id?: string | undefined;
  color?: CardColor;
  className?: string | null | undefined;
  title: any;
  buttonTitle?: string;
  onClick?: MouseEventHandler;
  secondaryButtonTitle?: any;
  onSecondaryClick?: MouseEventHandler | null | undefined;
  searchKey?: string | null | undefined;
  onSearch?: (newValue: string | null) => void;
  children?: any;
}) {
  let typeInfo = color ? cardColors[color] : null;
  const colorValue = typeInfo?.color;
  return (
    <div id={id} className={`compartment relative ${className || ""}`}>
      <div className="header">
        <div
          className={`compartmentTitle ${colorValue ? "" : "main-color"}`}
          style={{ color: colorValue }}
        >
          {title}
        </div>
        {onSecondaryClick != null ? (
          <Button type="tertiary" color={color} onClick={onSecondaryClick}>
            {secondaryButtonTitle || "Add"}
          </Button>
        ) : (
          <div></div>
        )}
        {onClick != null ? (
          <Button onClick={onClick} type={"primary"} color={color}>
            {buttonTitle || <IoMdAdd size={16} />}
          </Button>
        ) : (
          <div></div>
        )}
        {onSearch != null && (
          <div className="triple">
            <SearchBar
              value={searchKey}
              placeholder="Rechercher..."
              onChange={onSearch}
            ></SearchBar>
          </div>
        )}
      </div>

      <div className="content">{children}</div>
    </div>
  );
}

export function StatisticsCard({
  icon,
  title,
  value,
  color,
  onClick,
}: {
  icon?: any;
  title: string;
  value: any;
  color?: "red" | "green" | "blue" | "orange" | "purple" | "brown" | "pink";
  onClick?: () => void;
}) {
  return (
    <div
      className={`statisticsCard ${color || "blue"} ${
        onClick != null ? "pointer" : ""
      }`}
      onClick={onClick}
    >
      <div className={`icon`}>{icon}</div>
      <div className="c1 gap5">
        <div className="">{title}</div>
        <div className="bold f20">{value}</div>
      </div>
    </div>
  );
}

export function ReferenceCard({
  reference,
  searchKey,
  onClick,
}: {
  reference: Reference;
  searchKey: string;
  onClick?: () => void;
}) {
  const [isDeleted, setIsDeleted] = useState(false);
  if (isDeleted) return <></>;
  return (
    <Card
      color="simple"
      leading={<div></div>}
      onClick={async () => {
        if (!reference.id) return;
        await apiFetch("references", "incrementReferenceCount", {
          referenceId: reference.id,
        });
        onClick?.();
      }}
      trailing={<span className="gray">{reference.usageCount}</span>}
      onDelete={
        (reference.creator?.id || reference.creator) == getUserId()
          ? async () => {
              await apiFetch("general", "deleteAny", {
                id: reference.id,
                table: "references",
              });
              setIsDeleted(true);
            }
          : undefined
      }
    >
      <SearchKeyHighliter searchKey={searchKey}>
        {reference.name || ""}
      </SearchKeyHighliter>
    </Card>
  );
}
