import { InputChecker } from "@/src/components/formValidation";
import { MySelect } from "@/src/components/mySelect";
import { UploadSpot, UploadSpots } from "@/src/components/uploadSpots";
import { uid } from "@/src/utils/utils";
import { openToast } from "@/src/utils/toasts";
import moment from "moment";
import { useRef, useState } from "react";
import { Patient } from "../data/allTypes";
import { TextHistoryButton } from "./textHistoryButton";
import { openDialog, closeDialog } from "./dialogs";
import { CornerIconButton } from "./buttons";

export function Input({
  ref,
  readOnly,
  id,
  name,
  additionalUploadData,
  isHorizontal,
  showHistoryButton,
  isRequired,
  value,
  type,
  unitName,
  placeholder,
  onKeyUp,
  rows,
  options,
  onChange,
  onBlur,
}: {
  id?: string | null | undefined;
  ref?: React.RefObject<HTMLInputElement>;
  name: string | null | undefined;
  additionalUploadData?: Record<string, any>;
  value: any;
  patient?: Patient | null | undefined;
  showHistoryButton?: boolean;
  isHorizontal?: boolean;
  isRequired?: boolean;
  readOnly?: boolean;
  type: InputsType;
  rows?: number;
  unitName?: string;
  placeholder?: string;
  options?: {
    value: string | null | undefined;
    label: string | null | undefined;
  }[];
  onKeyUp?: (e: any) => void;
  onChange?: (newValue: any) => void;
  onBlur?: (newValue: any) => void;
}) {
  let [localValue, setLocalValue] = useState(value);
  return (
    <UniversalInput
      id={id}
      ref={ref}
      readOnly={readOnly}
      isHorizontal={isHorizontal}
      name={name}
      additionalUploadData={additionalUploadData}
      isRequired={isRequired}
      value={localValue}
      type={type}
      unitName={unitName}
      placeholder={placeholder}
      onKeyUp={onKeyUp}
      rows={rows}
      options={options}
      onChange={onChange}
      onBlur={onBlur}
      labelTrailing={
        (type == "textarea" || type == "htmlarea") &&
        showHistoryButton !== false && (
          <TextHistoryButton
            inputId={name || ""}
            currentText={localValue}
            onClick={(newValue) => {}}
            onSelect={(newValue) => {
              // let position = inputRef?.current?.selectionStart || 0;
              let position = 0;

              let newStr =
                localValue.slice(0, position) +
                newValue +
                localValue.slice(position);

              setLocalValue(newStr);
              onChange?.(newStr);
            }}
          ></TextHistoryButton>
        )
      }
    />
  );
}

export type InputsType =
  | "text"
  | "number"
  | "date"
  | "datetime"
  | "textarea"
  | "select"
  | "select-multiple"
  | "chips"
  | "chips-multiple"
  | "upload"
  | "uploads"
  | "boolean"
  | "variable"
  | "htmlarea"
  | "dialogSelect"
  | "password";

export function UniversalInput({
  id,
  ref,
  readOnly,
  isHorizontal,
  type,
  name,
  value,
  isRequired,
  placeholder,
  options,
  labelTrailing,
  additionalUploadData,
  unitName,
  rows,
  onChange,
  onKeyUp,
  onBlur,
}: {
  id?: string | null | undefined;
  ref?: React.RefObject<HTMLInputElement>;
  isHorizontal?: boolean;
  readOnly?: boolean;
  type: InputsType;
  name?: string | null | undefined;
  value: any;
  isRequired?: boolean;
  placeholder?: string;
  options?: {
    value: string | null | undefined;
    label: string | null | undefined;
  }[];
  labelTrailing?: any;
  additionalUploadData?: Record<string, any>;
  rows?: number;
  unitName?: string;
  onChange?: (newValue: any) => void;
  onKeyUp?: (e: any) => void;
  onBlur?: (newValue: any) => void;
}) {
  const [hasChanged, setHasChanged] = useState(false);
  let [localValue, setLocalValue] = useState(value);
  const [localOptions, setLocalOptions] = useState(options || []);
  const inputRef = useRef<any>(null);

  async function localOnKeyUp(e: any) {
    try {
      await onKeyUp?.(e);
    } catch (e: any) {
      openToast("error", e?.message || e?.toString());
    }
  }

  function onInputChange(e: any) {
    setLocalValue(e?.target?.value);
    onChange?.(e?.target?.value);
    setHasChanged(true);
  }

  function onInputBlur(e: any) {
    onBlur?.(localValue);
  }

  return (
    <InputChecker
      id={id}
      name={name}
      value={localValue}
      isRequired={isRequired}
      hasChanged={hasChanged}
    >
      <div
        className={`field ${unitName ? "hasFieldUnit" : ""} ${
          isHorizontal ? "horizontal" : ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {name != null ? (
          <div className="label">
            <div>
              {name}
              {isRequired == true && typeof name == "string" ? "*" : ""}
            </div>
            {labelTrailing}
          </div>
        ) : null}

        <div className="c1-auto">
          {(type == "select" || type == "select-multiple") && (
            <MySelect
              isMultiple={type == "select-multiple"}
              options={localOptions}
              value={localValue}
              placeholder={placeholder}
              onChange={(newValue) => {
                console.log("newvalue", newValue);
                setLocalValue(newValue);
                onChange?.(newValue);
              }}
            />
          )}

          {type == "dialogSelect" && (
            <DialogSelect
              gridCount={3}
              options={localOptions}
              current={localValue}
              onChange={(newValue) => {
                setLocalValue(newValue);
                onChange?.(newValue);
              }}
            ></DialogSelect>
          )}
          {type == "text" && (
            <input
              ref={ref || inputRef}
              value={localValue}
              type="text"
              placeholder={placeholder}
              onChange={onInputChange}
              onBlur={onInputBlur}
              onKeyUp={localOnKeyUp}
              readOnly={readOnly}
            />
          )}

          {type == "number" && (
            <input
              value={localValue}
              type="number"
              placeholder={placeholder}
              onChange={onInputChange}
              onBlur={onInputBlur}
              onKeyUp={localOnKeyUp}
              readOnly={readOnly}
            />
          )}

          {type == "password" && (
            <input
              value={localValue}
              type="password"
              placeholder={placeholder}
              onChange={onInputChange}
              onBlur={onInputBlur}
              onKeyUp={localOnKeyUp}
              readOnly={readOnly}
            />
          )}
          {type == "uploads" && (
            <UploadSpots
              gridCount={2}
              spotsCount={2}
              additionalUploadData={additionalUploadData}
              value={localValue}
              onChange={(newValue) => {
                onChange?.(newValue);
                setLocalValue(newValue);
              }}
            />
          )}
          {type == "upload" && (
            <UploadSpot
              additionalUploadData={additionalUploadData}
              value={localValue}
              onChange={(newValue) => {
                onChange?.(newValue);
                setLocalValue(newValue);
              }}
            />
          )}

          {type == "textarea" && (
            <textarea
              ref={inputRef}
              value={localValue}
              placeholder={placeholder}
              readOnly={readOnly}
              rows={rows}
              onChange={onInputChange}
              onBlur={onInputBlur}
              onKeyUp={localOnKeyUp}
            />
          )}

          {type == "datetime" && (
            <input
              readOnly={readOnly}
              value={localValue}
              type="datetime-local"
              onChange={(e) => {
                let newValue = moment(e.target.value).format(
                  "YYYY-MM-DD HH:mm:ss"
                );
                setLocalValue(newValue);
                onChange?.(newValue);
              }}
              onBlur={onInputBlur}
              onKeyUp={localOnKeyUp}
            />
          )}

          {type == "date" && (
            <input
              value={localValue}
              readOnly={readOnly}
              type="date"
              onChange={onInputChange}
              onBlur={onInputBlur}
              onKeyUp={localOnKeyUp}
            />
          )}

          {type == "chips" && (
            <div className={`chips ${rows ? `c${rows}` : ""}`}>
              {options?.map((option) => {
                return (
                  <div
                    key={uid()}
                    className={`chip ${
                      localValue == option.value ? "active" : ""
                    }`}
                    onClick={() => {
                      if (localValue == option.value) {
                        setLocalValue(null);
                        onChange?.(null);
                      } else {
                        setLocalValue(option.value);
                        onChange?.(option.value);
                      }
                    }}
                  >
                    {option.label}
                  </div>
                );
              })}
            </div>
          )}
          {type == "chips-multiple" && (
            <div className={`chips ${rows ? `c${rows}` : ""}`}>
              {options?.map((option) => {
                return (
                  <div
                    key={uid()}
                    className={`chip ${
                      localValue?.includes(option.value || "") ? "active" : ""
                    }`}
                    onClick={() => {
                      value ||= [];
                      let newValue = value.includes(option.value)
                        ? value.filter((val: any) => val != option.value)
                        : [...value, option.value];
                      setLocalValue(newValue);
                      onChange?.(newValue);
                    }}
                  >
                    {option.label}
                  </div>
                );
              })}
            </div>
          )}

          {type == "boolean" && (
            <div className={`chips ${rows ? `c${rows}` : ""}`}>
              {[
                { value: "true", label: "Oui" },
                { value: "false", label: "Non" },
              ]?.map((option) => {
                return (
                  <div
                    key={uid()}
                    className={`chip ${
                      localValue == option.value ? "active" : ""
                    }`}
                    onClick={() => {
                      setLocalValue(option.value);
                      onChange?.(option.value);
                    }}
                  >
                    {option.label}
                  </div>
                );
              })}
            </div>
          )}
          {unitName && <div className="fieldUnit">{unitName}</div>}
        </div>
      </div>
    </InputChecker>
  );
}

export function DialogSelect({
  current,
  options,
  gridCount,
  onChange,
}: {
  current: any;
  options: {
    value: any;
    label: string | null | undefined;
  }[];
  gridCount: number;
  onChange: (newValue: any) => void;
}) {
  const [value, setValue] = useState(current);
  function localOnChange(newValue: any) {
    setValue(newValue);
    onChange(newValue);
  }
  return (
    <div
      className="input relative"
      onClick={async () => {
        await openDialog({
          id: "selectDialog",
          title: "Selectionner",
          content: (
            <div
              className="grid gap10"
              style={{
                gridTemplateColumns: `repeat(${gridCount || 3}, 1fr)`,
              }}
            >
              {options.map((option) => {
                return (
                  <div
                    key={uid()}
                    className={`card tcenter ${
                      value == option.value ? "active" : ""
                    }`}
                    onClick={() => {
                      localOnChange(option.value);
                      closeDialog("selectDialog");
                    }}
                  >
                    {option.label || ""}
                  </div>
                );
              })}
            </div>
          ),
        });
      }}
    >
      {value || "Choisir une valeur"}
      {value != null && (
        <CornerIconButton
          type="delete"
          onClick={() => {
            localOnChange(null);
          }}
        ></CornerIconButton>
      )}
    </div>
  );
}
