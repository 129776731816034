import { Button } from "@/src/components/buttons";
import SearchBar from "@/src/components/searchBar";
import { uid } from "@/src/utils/utils";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

export function FetchableList<T>({
  queryFn,
  queryKey,
  searchKey,
  onSearch,
  onClick,
  emptyChild,
  children,
}: {
  queryFn:
    | Promise<T[] | null | undefined>
    | (() => Promise<T[] | null | undefined>)
    | (() => T[] | null | undefined);
  queryKey: any[];
  searchKey?: string | null | undefined;
  onSearch?: (newValue: string | null) => void;
  onClick?: (e: any) => void;
  emptyChild?: any;
  children: (data: T[] | null | undefined) => any;
}) {
  let { data, isLoading, isError, error, refetch } = useQuery({
    queryKey: queryKey,
    queryFn: () => (typeof queryFn == "function" ? queryFn?.() : queryFn),
  });

  return (
    <ListConstructor
      data={data}
      error={error}
      isError={isError}
      isLoading={isLoading}
      emptyChild={emptyChild}
      searchKey={searchKey}
      onSearch={onSearch}
      onClick={onClick}
    >
      {children(data || [])}
    </ListConstructor>
  );
}

export function InfiniteFetchableList<T>({
  getFunction,
  queryKey,
  limit,
  searchKey,
  onSearch,
  emptyChild,
  children,
  onClick,
}: {
  getFunction: (
    limit: number,
    offset: number,
    searchKey: string | null | undefined
  ) => Promise<T[] | null | undefined> | null;
  queryKey: any[];
  limit?: number;
  searchKey?: string | null | undefined;
  onSearch?: (newValue: string | null) => void;
  emptyChild?: any;
  onClick?: (e: any) => void;
  children: (data: T[] | null | undefined) => any;
}) {
  limit ||= 10;

  let {
    data,
    status,
    isLoading,
    error,
    isError,
    fetchNextPage,

    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: [...queryKey, searchKey],
    queryFn: ({ direction, meta, pageParam, queryKey, signal }) => {
      let offset = pageParam * (limit || 10);

      return getFunction(limit!, offset, searchKey);
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (allPages?.length > 0) {
        let hasNext = true;
        for (let page of allPages || []) {
          if ((page?.length || 0) >= limit!) continue;
          hasNext = false;
          break;
        }
        if (!hasNext) return undefined;
      }
      return lastPageParam + 1;
    },
  });

  let isEmpty = true;
  for (let page of data?.pages || []) {
    if (!page) continue;
    if (page?.length > 0) {
      isEmpty = false;
      break;
    }
  }

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasNextPage) fetchNextPage();
  }, [inView, hasNextPage]);

  return (
    <div className="infiniteList">
      <div className="c1 gap10">
        {onSearch && (
          <div className="c1-auto gap10">
            <SearchBar
              placeholder="Rechercher..."
              value={searchKey}
              onChange={onSearch}
            ></SearchBar>
            {onClick && <Button onClick={onClick}>Ajouter</Button>}
          </div>
        )}

        {isEmpty &&
          !isLoading &&
          !isError &&
          (emptyChild || (
            <div className="grid center p30 gray">Aucun élément</div>
          ))}

        {!isEmpty && !isLoading && !isError
          ? data?.pages.map((page) => {
              return <div key={uid()}>{children(page || [])}</div>;
            })
          : null}

        {isError && (
          <div className="red-bg lc p20 center">
            Error {JSON.stringify(error?.message) || ""}
          </div>
        )}
        {(isLoading || status == "pending" || isFetchingNextPage == true) && (
          <div className=" gray grid c1 gap10">
            <div className="skeletonTile"></div>
            <div className="skeletonTile"></div>
            <div className="skeletonTile"></div>
          </div>
        )}
      </div>

      <div ref={ref} className="mtop10">
        <div
          style={{ height: "10px", width: "100%", background: "transparent" }}
        ></div>
      </div>
    </div>
  );

  // return (

  // );
}

export function ListConstructor({
  data,
  isLoading,
  isError,
  error,
  emptyChild,
  searchKey,
  onSearch,
  onClick,
  children,
}: {
  data: any[] | null | undefined;
  isLoading: boolean;
  isError?: boolean;
  error?: any;
  emptyChild?: any;
  searchKey?: string | null | undefined;
  onSearch?: (newValue: string) => void;
  onClick?: (e: any) => void;
  children: any;
}) {
  let isEmpty = data && !data?.length && emptyChild !== false;
  return (
    <div className="c1 gap10">
      {onSearch && (
        <div className="c1-auto gap10">
          <SearchBar
            placeholder="Rechercher..."
            value={searchKey}
            onChange={onSearch}
          ></SearchBar>
          {onClick && <Button onClick={onClick}>Ajouter</Button>}
        </div>
      )}
      {isLoading && (
        <div className=" gray grid c1 gap10">
          <div className="skeletonTile"></div>
          <div className="skeletonTile"></div>
          <div className="skeletonTile"></div>
        </div>
      )}
      {isError && (
        <div className="red-bg lc p20 center">
          Error {JSON.stringify(error.message) || ""}
        </div>
      )}
      {isEmpty &&
        !isLoading &&
        !isError &&
        (emptyChild || (
          <div className="c1 center gap20 p30 gray">
            <div>Aucun élément trouvé</div>
            {onClick != null && (
              <Button type="tertiary" onClick={onClick}>
                Ajouter
              </Button>
            )}
          </div>
        ))}
      {!isEmpty && !isLoading && !isError && children}
    </div>
  );
}


export function StreamListConstructor({
  data,
  isLoading,
  isError,
  error,
  emptyChild,
  searchKey,
  onSearch,
  onClick,
  children,
}: {
  data: any[] | null | undefined;
  isLoading: boolean;
  isError?: boolean;
  error?: any;
  emptyChild?: any;
  searchKey?: string | null | undefined;
  onSearch?: (newValue: string) => void;
  onClick?: (e: any) => void;
  children: any;
}) {
  let isEmpty = data && !data?.length && emptyChild !== false;
  return (
    <div className="c1 gap10">
      {onSearch && (
        <div className="c1-auto gap10">
          <SearchBar
            placeholder="Rechercher..."
            value={searchKey}
            onChange={onSearch}
          ></SearchBar>
          {onClick && <Button onClick={onClick}>Ajouter</Button>}
        </div>
      )}
      {isLoading && (
        <div className=" gray grid c1 gap10">
          <div className="skeletonTile"></div>
          <div className="skeletonTile"></div>
          <div className="skeletonTile"></div>
        </div>
      )}
      {isError && (
        <div className="red-bg lc p20 center">
          Error {JSON.stringify(error.message) || ""}
        </div>
      )}
      {isEmpty &&
        !isLoading &&
        !isError &&
        (emptyChild || (
          <div className="c1 center gap20 p30 gray">
            <div>Aucun élément trouvé</div>
            {onClick != null && (
              <Button type="tertiary" onClick={onClick}>
                Ajouter
              </Button>
            )}
          </div>
        ))}
      {!isEmpty && !isLoading && !isError && children}
    </div>
  );
}
