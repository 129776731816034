import { useRef, useState, useEffect } from "react";
import { IoSearch } from "react-icons/io5";
import { debounce } from "@/src/utils/utils";

export default function SearchBar({
  autoFocus,
  value,
  placeholder,
  onChange,
}: {
  autoFocus?: boolean;
  value?: string | null;
  placeholder?: string;
  onChange?: (newValue: string) => void;
}) {
  const searchRef = useRef<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | null
  >(null);

  const [localValue, setLocalValue] = useState(value || "");
  useEffect(() => {
    if (autoFocus) {
      searchRef.current?.focus();
    }
  }, []);

  return (
    <div className="searchBar">
      <IoSearch size={20} color="RGBA(0,0,0,0.4)" />
      <input
        ref={searchRef as any}
        type="text"
        value={localValue || ""}
        placeholder={placeholder}
        onChange={(e) => {
          let newValue = e.target.value || "";
          setLocalValue(newValue);
          debounce(
            "searchDebounce",
            () => {
              onChange?.(newValue);
            },
            500
          );
        }}
      />
    </div>
  );
}

