"use client";
import { useInView } from "react-intersection-observer";

export default function StaggeredAnimation({
  index,
  increment,
  children,
}: {
  index: number | null | undefined;
  increment?: number;
  children: any;
}) {
  if (!increment) increment = 70;
  if (index === null || index === undefined) return children;
  return (
    <div
      className="staggered"
      style={{ animationDelay: `${index * increment}ms` }}
    >
      {children}
    </div>
  );
}

export function InViewStaggeredAnimation({
  index,
  increment,
  children,
}: {
  index: number | null | undefined;
  increment?: number;
  children: any;
}) {
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });
  if (!increment) increment = 70;
  if (index === null || index === undefined) return children;
  return (
    <div
      ref={ref}
      className={inView ? "staggered" : ""}
      style={{
        animationDelay: `${index * increment}ms`,
        opacity: inView ? 0 : 0,
      }}
    >
      {children}
    </div>
  );
}
