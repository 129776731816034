import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { RiHistoryFill } from "react-icons/ri";
import { TextHistory } from "../data/allTypes";
import { USER } from "../data/globalVariables";
import apiFetch from "../utils/apiFetch";
import { Button, IconButton } from "./buttons";
import { Card } from "./cards";
import { closeDrawer, openDrawer } from "./dialogs";
import { FetchableList, ListConstructor } from "./fetchableLists";
import { uid } from "../utils/utils";

export function TextHistoryButton({
  inputId,
  currentText,
  onClick,
  onSelect,
}: {
  inputId: string;
  currentText: string;
  onClick: (e: any) => void;
  onSelect: (newValue: string) => void;
}) {
  return (
    <IconButton
      onClick={async (e) => {
        onClick(e);
        await openDrawer({
          id: "textHistoryDrawer",
          title: "Historique",
          content: (
            <TextHistoryList
              inputId={inputId}
              currentText={currentText}
              onSelect={(item) => {
                onSelect(item.value);
                closeDrawer("textHistoryDrawer");
              }}
            ></TextHistoryList>
          ),
          footer: <div></div>,
        });
      }}
    >
      <RiHistoryFill />
    </IconButton>
  );
}

function TextHistoryList({
  inputId,
  currentText,
  onSelect,
}: {
  inputId?: string;
  currentText?: string;
  onSelect: (newValue: TextHistory) => void;
}) {
  const [reload, setReload] = useState(0);
  let [searchKey, setSearchKey] = useState("");

  async function openItemDrawer(textHistory: TextHistory) {
    await openDrawer({
      title: "Ajouter un élément à l'historique",
      content: (
        <TextHistoryForm
          current={textHistory}
          onChange={async (newValue) => {
            textHistory = newValue;
          }}
        />
      ),
      onConfirm: async () => {
        await apiFetch("textHistories", "addOne", { textHistory });
        setReload((prev) => prev + 1);
      },
    });
  }

  return (
    <div>
      <FetchableList
        queryKey={["TextHistoryList", searchKey, reload]}
        queryFn={() =>
          apiFetch("textHistories", "loadMany", {
            inputId,
            searchKey,
            creatorId: USER?.get()?.id,
          })
        }
        searchKey={searchKey}
        onSearch={(newValue) => {
          setSearchKey(newValue || "");
        }}
        emptyChild={
          <div className="center grid c1 gap10 p20">
            <div className="bold">Aucun texte réutilisable trouvé</div>
            <div className="gray tcenter">
              Enregistrez un nouveau pour le réutiliser en un clic dès que vous
              en avez besoin
            </div>
            <Button
              onClick={async () => {
                let textHistory: TextHistory = {
                  name: `${searchKey || ""}`,
                  value: currentText || "",
                  creator: USER?.get(),
                  inputId,
                };

                await openItemDrawer(textHistory);
              }}
            >
              Créer {searchKey || "un texte réutilisable"}
            </Button>
          </div>
        }
      >
        {(data) => (
          <div className="grid c1 gap10">
            {data?.map((textHistory, index) => {
              return (
                <Card
                  key={uid()}
                  color="simple"
                  title={textHistory.name}
                  onClick={async () => {
                    onSelect(textHistory);
                  }}
                  onEdit={async () => {
                    await openItemDrawer(textHistory);
                  }}
                >
                  <div
                    className="gray ellipsis"
                    style={{
                      width: "100%",
                      maxHeight: "50px",
                      maxWidth: "300px",
                    }}
                  >
                    {textHistory.value}
                  </div>
                </Card>
              );
            })}
          </div>
        )}
      </FetchableList>
    </div>
  );
}

function TextHistoryForm({
  current,
  onChange,
}: {
  current?: TextHistory;
  onChange: (newValue: TextHistory) => void;
}) {
  let [data, setData] = useState(current || ({} as TextHistory));
  function setNewValue(newValue: TextHistory) {
    setData(newValue);
    onChange(newValue);
  }

  return (
    <div className="grid c1 gap10">
      <div className="label">
        <div>Nom</div>
      </div>
      <input
        value={data.name}
        type="text"
        placeholder="Ex: Examen clinique normal"
        onChange={(e) => {
          setNewValue({ ...data, name: e.target.value });
        }}
      />

      <div className="label">
        <div>Valeur</div>
      </div>
      <input
        name="Valeur"
        value={data.value}
        type="textarea"
        placeholder="Ex: Patient en bon état général..."
        onChange={(e) => {
          setNewValue({ ...data, value: e.target.value });
        }}
      />
    </div>
  );
}
