import "lightbox.js-react/dist/index.css";
import { SlideshowLightbox } from "lightbox.js-react";
import { useEffect, useState } from "react";
import { GALLERY_IMAGES } from "@/src/data/globalVariables";
import Viewer from "viewerjs";
import React from "react";

export function ImageViewer({
  images,
  children,
}: {
  images: { src: string | null | undefined }[];
  children: any;
}) {
  return <SecondImageViewer images={images}>{children}</SecondImageViewer>;
  let [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation();
          GALLERY_IMAGES.set(images);
          console.log("click", images);
        }}
      >
        {children}
      </div>
    </>
  );
}

export function RootImageViewer() {
  let images = GALLERY_IMAGES.useSubscribe(`ImagesGallery`) || [];
  return (
    <SlideshowLightbox
      startingSlideIndex={0}
      images={images}
      showThumbnails={true}
      open={(images?.length || 0) > 0}
      lightboxIdentifier="lbox1"
      onClose={() => {
        GALLERY_IMAGES.set(null);
      }}
    ></SlideshowLightbox>
  );
}

export function SecondImageViewer({
  images,
  children,
}: {
  images: { src: string | null | undefined }[];
  children: any;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    const viewer = new Viewer(ref.current!, {
      container: document.body,
      fullscreen: true,
      navbar: false,
      title: false,
      toggleOnDblclick: false,
      play: undefined,
      toolbar: {
        zoomIn: 4,
        zoomOut: 4,
        oneToOne: 0,
        reset: 0,
        prev: 1,
        play: {
          show: 0,
          size: "large",
        },
        next: 1,
        rotateLeft: 1,
        rotateRight: 1,
        flipHorizontal: 0,
        flipVertical: 0,
      },
    });
  }, []);
  return (
    <>
      <div ref={ref}>{children}</div>
    </>
  );
}
