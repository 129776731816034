export default function SearchKeyHighliter({
  className,
  searchKey,
  children,
}: {
  className?: string | null | undefined;
  searchKey: string | null | undefined;
  children: string | null | undefined;
}) {
  if (!searchKey) return <>{children}</>;
  if (!children) return <></>;
  let parts = children.toLowerCase().split(new RegExp(`(${searchKey})`, "gi"));
  return (
    <span>
      {parts.map((part, index) => {
        return part.toLowerCase() === searchKey.toLowerCase() ? (
          <span
            key={index}
            className={`${className || ""} highlight bold capitalize`}
          >
            {part}
          </span>
        ) : (
          part
        );
      })}
    </span>
  );
}
